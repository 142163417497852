import React from 'react'
import Creative from './Creative'
import Pledge from '../Pledge'
import WeAiims from '../WeAiims'
import Support from './Support'
import Header from '../Header'

function Homepage({permission}) {
 
  return (
    <div>
      <Header/>
     <Support/>
      <Creative permission={permission}/>
      <Pledge permission={permission}/>
      <WeAiims/>
    </div>
  )
}

export default Homepage