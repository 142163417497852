import './App.css';
import Homepage from './components/homepage/Homepage';
import Camera from './components/camera/Camera';
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import { Routes } from 'react-router-dom';
import MyPledge from './components/pledge/MyPledge';
import { useEffect, useState } from 'react';
function App() {
  
  const [longitude,setLongitude]=useState("")
  const [latitude,setLatitude]=useState("")
  const [permission,setPermission]=useState(false)
  useEffect(()=>{
    myLocation()
   },[])
   const myLocation=()=>{
       if (navigator.geolocation) {
           navigator.geolocation.getCurrentPosition(showPosition,showError);
         }
   }
   function showPosition(position) {
    setPermission(true)
       setLatitude(position.coords.latitude)
       setLongitude(position.coords.longitude)
     }
     function showError(error) {
      switch(error.code) {
        case error.PERMISSION_DENIED:
          setPermission(false)
          break;
        case error.POSITION_UNAVAILABLE:
          alert("Location information is unavailable.")
          break;
        case error.TIMEOUT:
          alert("The request to get user location timed out.")
          break;
        case error.UNKNOWN_ERROR:
          alert("An unknown error occurred.")
          break;
      }
    }
  return (
    <div className='main-app'>

    <Router>
    <Routes>
      <Route path="/" element={<Homepage permission={permission}/>} exact/>
      <Route path="/on-camera" element={<Camera latitude={latitude} longitude={longitude} setLatitude={setLatitude} setLongitude={setLongitude}/>} exact/>
      <Route path="/pledge" element={<MyPledge latitude={latitude} longitude={longitude}/>} exact/>
    </Routes>
    </Router>
    </div>

  );
}

export default App;
