import React, { useEffect, useState } from 'react'
import Pledge from '../Pledge'
import axios from "axios"
import FormData from 'form-data'
import request from '../request/request'
import {TwitterShareButton,FacebookShareButton,InstapaperShareButton,LinkedinShareButton} from "react-share"
import { WhatsappShareButton } from 'react-share'
function CapturePhoto({ picture,myPicData,setHeaderData,longitude,latitude}) {
    const [shareUrl,setShareUrl]=useState("")
    const [shareSocialUrl,setShareSocialUrl]=useState("")
    const fetchdata = async () => {
        myPicData=picture
        const dataURLtoFile = (dataurl, filename) => {
            const arr = dataurl.split(',')
            const mime = arr[0].match(/:(.*?);/)[1]
            const bstr = atob(arr[1])
            let n = bstr.length
            const u8arr = new Uint8Array(n)
            while (n) {
              u8arr[n - 1] = bstr.charCodeAt(n - 1)
              n -= 1 // to make eslint happy
            }
            return new File([u8arr], filename, { type: mime })
          }
          // generate file from base64 string
          const file = dataURLtoFile(picture)
          // put file into form data
          const data = new FormData()
          data.append('file', file, file.name)
          data.append("pledge",false)
          data.append("lat",latitude)
          data.append("long",longitude)
          const res=await axios.post(request.postSelfie,data, { withCredentials: true } )
          if(res){
            setHeaderData(Math.random())
            setShareUrl(request.base_fe_url + res.data.data.slug)
            setShareSocialUrl(request.base_fe_url + res.data.data.slug_social)
          }
    }
    useEffect(() => {
        
        if(myPicData === ""){
            fetchdata()
        }
    }, [picture])
    return (
        <div>
            <div className='support-camera'>
                <div className='capture-logo'>

                    <div className="flex-box">
                        <img src="images/Group 7.png" className='left-auto' alt="" />
                    </div>
                </div>
                <div className='width-100 m-negative'>
                    <img className='width-100 margin-left-14 border-radius-20' src={picture} alt="" />
                </div>
                <div className='support-logo'>
                    <img src="images/Group 17.png" alt='support-logo' />
                </div>
            </div>
            <div className='social-icons'>
                <ul>
                    <li>
                        <div className='share-icon'>
                            <img src="images/icons/share.png" alt='share icon' />
                            <span>Share</span>
                        </div>

                    </li>
                    <li>
                        <TwitterShareButton url={shareSocialUrl}>
                        <img src="images/icons/twitter.png" alt='twitter' />
                            </TwitterShareButton>
                    </li>
                    <li>
                        <FacebookShareButton url={shareSocialUrl}>
                        <img src="images/icons/facebook.png" alt='facebook' />
                        </FacebookShareButton>
                    </li> 
										<li>
                        <WhatsappShareButton url={shareUrl}>
                        <img src="images/icons/whatsapp.png" alt="whatsapp" />
                        </WhatsappShareButton>
                    </li>
                </ul>
            </div>
            <Pledge />
        
        </div>
    )
}

export default CapturePhoto
