import React, {  useState } from 'react'
import Header from '../Header'
import WeAiims from '../WeAiims'
import CameraPledge from './CameraPledge'
import CapturePhoto from './CapturePhoto'

function Camera({latitude,longitude}) {
    const [picture,setPicture]=useState("")
    // const [myPicData,setMyPicData]=useState("")
    const [headerData,setHeaderData]=useState("")

    let myPicData=""
  return (
    <div>
        <Header headerData={headerData}/>
        {picture === ""?
        <CameraPledge picture={picture} setPicture={setPicture} setHeaderData={setHeaderData}/>:
        <CapturePhoto longitude={longitude} latitude={latitude} picture={picture} myPicData={myPicData} setHeaderData={setHeaderData}/>}
        <WeAiims/>
        {/* <p className='grey-color'>
      location {latitude} {longitude}
      </p> */}
    </div>
  )
}

export default Camera