const BASE_API_URL= process.env.REACT_APP_API_HOST + "/"
const BASE_FE_URL= process.env.REACT_APP_FE_HOST
const request={
		base_url: BASE_API_URL,
		base_fe_url: BASE_FE_URL,
    postSelfie:BASE_API_URL+"cap_selfie",
    getSelieCount:BASE_API_URL+"get_ps",
    mySelfie:BASE_API_URL+"get_selfies",
}
export default request
