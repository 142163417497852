import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Webcam from "react-webcam";
const videoConstraints = {
    width: 400,
    height: 400,
    facingMode: "user"
};
function CameraPledge({ picture, setPicture}) {
    const webcamRef = React.useRef(null);
   
    const capture = () => {
        const pictureSrc = webcamRef.current.getScreenshot();
        if (pictureSrc) {
            setPicture(pictureSrc);
        }
    }
    const navigate = useNavigate()
    return (
        <div>
            <div className='support-camera'>
                <div className='width-100 m-negative text-center'>
                    {picture === "" ? (
                        <Webcam
                            audio={false}
                            height={400}
                            ref={webcamRef}
                            width={400}
                            screenshotFormat="image/jpeg"
                            className='border-radius-20'
                            videoConstraints={videoConstraints}
                        />) :
                        <img className='width-100' src={picture} alt="" />}
                </div>
                <div className='support-logo'>
                    <img src="images/Group 17.png" alt='' />
                </div>
            </div>
            <div className='capture-image'>
                <div>

                    <div className='click-photo-border'>
                        <div className='click-photo cursor' onClick={(e) => {
                            e.preventDefault();
                            capture();
                        }}>
                            <img src="images/right-tick.svg" alt='' />
                        </div>
                    </div>
                    <div className='photo-text'>Click photo</div>
                </div>
                <div className='margin-95'>
                    <div>

                        <div className='close-photo-border cursor' onClick={() => navigate("/")}>
                            <div className='close-photo'>
                                <img src="images/close-photos.svg" alt='' />
                            </div>
                        </div>
                        <div className='photo-text'>
                            Try Again
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CameraPledge