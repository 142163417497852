import axios from 'axios'
import React, { useEffect, useState } from 'react'
import request from './request/request'

function Header({headerData}) {
  const [data,setData]=useState([])
  const fetchData=async ()=>{
    const res=await axios.get(request.getSelieCount,{withCredentials:true} )
    setData(res.data)
    }
  useEffect(()=>{
   fetchData()
  },[])
  useEffect(()=>{
   fetchData()
  },[headerData])
  const news=()=>{
    window.open("https://nammabagalkot.com/")
  }
  const voiceBagalkot=()=>{
    window.open("https://voiceofbagalkot.nammabagalkot.com/")
  }
  return (
    <div>
      <div className='header-bg'>
        <div className='flex-box flex-align-center' style={{justifyContent:"space-between"}}>
          <button className="header-btn header-btn-text uppercase-text" onClick={news}>BAGALKOT NEWS</button>
          <button className="header-btn header-btn-text uppercase-text" onClick={voiceBagalkot}>VOICE OF BAGALKOT</button>
          <div className='download-btn'>
          Change your FB Cover
          <a href='images/Namma FB Banner.jpg' download="images/Namma FB Banner.jpg">
          <img src="images/download_icon.png"/>
            </a> 
          </div>
        </div>
        <div className='heading-mt-mb'>
          <h1 className="text-heading">#aiims <span className='text-for'>for</span> bagalkote</h1>
        </div>
        <div className="flex-box">
          <img src="images/initiative/initiative.png" className='left-auto' alt="initiative" />
        </div>
      </div>
      <div className='padding-selfie'>
        <div className='selfie-content'>
          <div className='selfie'>
            <div className='selfie-box'>
              <div>
                <img src='images/selfie/selfie.png' alt='selfie'/>
              </div>
              <div className='selfie-text'>Selfie</div>
            </div>
            <div className='selfie-count'>{data.selfies}</div>
          </div>
          <div className='selfie'>
            <div className='selfie-box'>
              <div>
                <img src='images/pledge/pledge.png' alt='selfie box'/>
              </div>
              <div className='selfie-text'>Pledge</div>
            </div>
            <div className='pledge-count'>{data.pledges}</div>
          </div>
        </div>
      </div>

    </div>

  )
}

export default Header
