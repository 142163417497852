import axios from 'axios'
import React, { useEffect, useState } from 'react'
import request from './request/request'
function WeAiims() {
  const [selfieData,setSelfieData]=useState([])
  const [pageNo,setPageNo]=useState(1)
  
  useEffect(()=>{
   fetchData()
  },[])
  
  useEffect(()=>{
  fetchData()
  },[pageNo])

  const fetchData=async ()=>{
    const data={
      page:pageNo
    }
    const res=await axios.get(request.mySelfie,{params:data} , { withCredentials: true } );
    if(selfieData.length===0){
      setSelfieData(res.data?.data)
    }else{
      setSelfieData([...selfieData,...res.data?.data])
    }
   }
  return (
    <div className='we-aiims'>
        <div>
            <p className='we-aiims-text'>We for AIIMS</p>
        </div>
        <div className='flex-box justify-content-end flex-wrap'>
            {selfieData?.map((value,index)=>(
            <div className='aiims-box' key={index}>
              <img alt='capture_photo' style={{objectFit:"cover",backgroundColor:"grey",borderRadius:"6px"}} src={request.base_url + value[0]} className="w-100 h-100"/>
            </div>
            ))}
        </div>
        <div className='more-btn-detail'>

        <div className='more-button' onClick={()=>setPageNo(pageNo+1)}>
          More
        </div>
        
        </div>
    </div>
  )
}

export default WeAiims
