import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';

function Pledge({permission}) {
    const navigate=useNavigate()
    const signPitition=()=>{
        window.open("https://chng.it/J7fpJgkngS")
    }
    const pledgeBtn=()=>{
        if (permission) { 
            navigate("/pledge") 
        }
        else {
            toast('This application needs to know your location for working. Kindly enable location access from settings to continue.', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        }
    }
    return (
        <div className='pledge-main'>
            <div className='pledge'>
                <div className='pledge-aims margin-right-10'>
                    <div className='pledge-text'>
                        PLEDGE FOR AIIMS
                    </div>
                    <button className='pledge-btn cursor' onClick={pledgeBtn}>click</button>
                </div>
            </div>
            <div className='pledge'>
                <div className='pledge-aims margin-left-10'>
                    <div className='pledge-text'>
                    SIGN THIS PETITION
                    </div>
                    <button className='pledge-btn cursor' onClick={signPitition}>click</button>
                </div>
            </div>
            <ToastContainer
        position="top-left"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
        </div>
    )
}

export default Pledge