import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';

function Creative({ permission }) {
    const navigate = useNavigate()
    const cameraOnbtn = () => {
        if (permission) { 
            navigate("/on-camera") 
        }
        else {
            toast('This application needs to know your location for working. Kindly enable location access from settings to continue.', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        }
    }
    return (
        <div className='creative'>
            <div className='flex-box'>
                <div className='left-auto font-11 not-required'>
                    *Mobile Number Not Required
                </div>
            </div>
            <div className='click-creative'>
                <button className='creative-btn cursor' onClick={cameraOnbtn}>
                    Click here to create your creative
                </button>
            </div>
            <ToastContainer
        position="top-left"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
        </div>
    )
}

export default Creative