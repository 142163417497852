import React, { useState } from 'react'
import Header from '../Header'
import Pledge from '../Pledge'
import WeAiims from '../WeAiims'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import request from '../request/request';
import { useNavigate } from "react-router-dom"
import { TwitterShareButton, FacebookShareButton, InstapaperShareButton, LinkedinShareButton } from "react-share"
import { WhatsappShareButton } from 'react-share'
function MyPledge({latitude,longitude}) {
  const [shareUrl, setShareUrl] = useState("")
  const [shareSocialUrl, setShareSocialUrl] = useState("")
  const navigate = useNavigate()
  const takePledge = async (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append("pledge", true)
    data.append("lat",latitude)
    data.append("long",longitude)
    const res = await axios.post(request.postSelfie, data, { withCredentials: true } )
    if (res) {
      toast('Thank You For Taking Pledge', {
        position: "top-left",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setShareUrl(request.base_fe_url + res?.data?.data?.pledgeshareurl)
      setShareSocialUrl(request.base_fe_url + res?.data?.data?.pledgesharesocialurl)
    }
  }
  return (
    <div>
      <Header />
    
      <div className='image-pledge'>
        <img src="images/pledge/my-pledge.jpeg" />
        {/* <div className='my-pledge-content'>
          <div className='my-pledge-text'>
            I pledge to ensure that Bagalkot has an affordable and high-quality hospital. I will continue my fight until AIIMS hospital is built in Bagalkot.
          </div>
        </div> */}
      </div>
      <div className='take-pledge'>
        <button className='take-pledge-btn cursor' onClick={takePledge}>Take Pledge</button>
      </div>
      <ToastContainer
        position="top-left"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {shareUrl &&
        <div className='social-icons' style={{paddingTop:"0px"}}>
          <ul>
            <li>
              <div className='share-icon'>
                <img src="images/icons/share.png" alt='share icon' />
                <span>Share</span>
              </div>

            </li>
            <li>
              <TwitterShareButton url={shareSocialUrl}>
                <img src="images/icons/twitter.png" alt='twitter' />
              </TwitterShareButton>
            </li>
            <li>
              <FacebookShareButton url={shareSocialUrl}>
                <img src="images/icons/facebook.png" alt='facebook' />
              </FacebookShareButton>
            </li>
						<li>
              <WhatsappShareButton url={shareUrl}>
                <img src="images/icons/whatsapp.png" alt="whatsapp" />
              </WhatsappShareButton>
            </li>
          </ul>
        </div>
      }
      <Pledge />
      <WeAiims />
      {/* <p className='grey-color'>
      location {latitude} {longitude}
      </p> */}
    </div>
  )
}

export default MyPledge
