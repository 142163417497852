import React from 'react'

function Support() {
  return (
    <div className='support'>
    <div className='width-100 m-negative'>
    <img className='width-100' src='images/Group 1.png' alt=''/>
    </div>
   <div className='support-logo'>
    <img src="images/Group 17.png" alt='support-logo'/>
   </div>
  </div>
  )
}

export default Support